import './App.css';
import Menu from "./components/menu/menu";
import Block1 from "./components/block1/block1";
import Block2 from "./components/block2/block2";
import Block3 from "./components/block3/block3";
import Block4 from "./components/block4/block4";
import Block5 from "./components/block5/block5";
import Block6 from "./components/block6/block6";
import Block7 from "./components/block7/block7";
import Block8 from "./components/block8/block8";
import Block9 from "./components/block9/block9";
import Block10 from "./components/block10/block10";
import Popup from "./components/popup/popup";
import {useEffect, useState} from "react";
import Loading from "./components/loading/loading";
import MediaQuery from "react-responsive";
import List from "./components/list/list";

function App() {
    const [close, setClose] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        window.onload = () => setLoading(false);
        setTimeout(()=>setLoading(false), 5000)
    },[])


  return (
    <div className="App">
      <Loading loading={loading}/>
      <Menu popup={()=>setClose(true)}/>
      <Block1 popup={()=>setClose(true)}/>
      <Block2/>
      <Block3/>
      <Block4/>
        <MediaQuery maxWidth={824}>
            <List/>
        </MediaQuery>
      <Block7/>
      <Block6/>
      <Block8/>
      <Block9/>
      <Block10/>
      {close && <Popup close={()=>setClose(false)}/>}
    </div>
  );
}

export default App;
