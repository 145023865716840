export async function new_client(name,tel,date,question,format){
    let user = {
        name:name,
        phone:tel,
        date:date,
        question:question,
        format:format,
    };
    let response = await fetch('https://api.topdayproduction.ru/get_contact', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    console.log(await response.json())
}
