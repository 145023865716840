import React, {useState} from 'react';
import classes from "./menu.module.css";
import logo from '../../image/logo.png';
import logo2 from '../../image/logo2.png'
import Button from "../button/button";
import MediaQuery from "react-responsive";
import Call from "../call/call";

const Menu = ({popup}) => {
    const items = [
        {id:0,text:'Как мы работаем', href:'#work'},
        {id:1,text:'портфолио', href:'#cases'},
        {id:2,text:'цены', href:'#price'},
        {id:3,text:'о нас', href:'#about'},
    ]
    const [openMenu, setOpenMenu] = useState(false)

    const [hover1, setHover1] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [hover3, setHover3] = useState(false)
    const [hover4, setHover4] = useState(false)

    return (
        <div className={classes._}>
            <div className={classes.block1} onClick={()=>window.location.href='#top'}>
                <MediaQuery minWidth={1470}>
                    <img className={classes.logo} src={logo} alt={''}/>
                </MediaQuery>
                <MediaQuery maxWidth={1469} minWidth={825}>
                    <img className={classes.logo2} src={logo2} alt={''}/>
                </MediaQuery>
                <MediaQuery maxWidth={824}>
                    <img className={classes.logo} src={logo} alt={''}/>
                </MediaQuery>
            </div>
            <div className={classes.block2}>
                <MediaQuery minWidth={825}>
                    <div className={classes.menuBlock}>
                    <MediaQuery minWidth={1470}>
                        {items.map(item =>
                            <div key={item.id} className={classes.menuItem} onClick={()=>window.location.href=item.href}>{item.text}</div>
                        )}
                    </MediaQuery>
                    <MediaQuery maxWidth={1469}>
                        <svg className={classes.iconMenu}
                             onClick={()=>window.location.href='#work'}
                             onMouseEnter={()=>setHover1(true)}
                             onMouseLeave={()=>setHover1(false)}
                             xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                            <path d="M7.30651 3.53383C7.94859 1.93314 8.26962 1.1328 8.7912 1.02187C8.92835 0.992708 9.07009 0.992708 9.20724 1.02187C9.72882 1.1328 10.0499 1.93314 10.6919 3.53383C11.0571 4.44411 11.2396 4.89925 11.5812 5.20882C11.6771 5.29565 11.7811 5.37298 11.8918 5.43973C12.2867 5.6777 12.7796 5.72185 13.7653 5.81013C15.4341 5.95958 16.2685 6.03431 16.5233 6.51005C16.576 6.60858 16.6119 6.71526 16.6294 6.82566C16.7139 7.35868 16.1005 7.91674 14.8737 9.03286L14.533 9.3428C13.9595 9.86461 13.6727 10.1255 13.5069 10.4511C13.4074 10.6464 13.3406 10.8568 13.3094 11.0737C13.2573 11.4354 13.3412 11.8139 13.5092 12.5709L13.5692 12.8414C13.8704 14.1989 14.021 14.8777 13.833 15.2113C13.6641 15.511 13.3531 15.7029 13.0095 15.7193C12.627 15.7375 12.088 15.2983 11.01 14.4199C10.2998 13.8412 9.94469 13.5519 9.55048 13.4388C9.19023 13.3355 8.80821 13.3355 8.44796 13.4388C8.05375 13.5519 7.69865 13.8412 6.98844 14.4199C5.91046 15.2983 5.37147 15.7375 4.98895 15.7193C4.64535 15.7029 4.33431 15.511 4.16544 15.2113C3.97745 14.8777 4.12805 14.1989 4.42924 12.8414L4.48925 12.5709C4.6572 11.8139 4.74118 11.4354 4.68907 11.0737C4.65781 10.8568 4.59109 10.6464 4.49159 10.4511C4.32572 10.1255 4.03894 9.86461 3.4654 9.3428L3.12473 9.03286C1.89794 7.91674 1.28454 7.35868 1.36902 6.82566C1.38651 6.71526 1.42239 6.60858 1.47516 6.51005C1.72996 6.03431 2.56434 5.95958 4.2331 5.81013C5.21889 5.72185 5.71178 5.6777 6.10662 5.43973C6.21737 5.37298 6.32138 5.29565 6.4172 5.20882C6.75881 4.89925 6.94138 4.44411 7.30651 3.53383Z" fill="white" stroke="white" strokeWidth="2"/>
                        </svg>
                        <div className={classes.menuHover} style={{marginTop:'10px',opacity:hover1?1:0,zIndex:hover1?1:-1}}>Как мы работаем</div>
                        <svg className={classes.iconMenu}
                             onClick={()=>window.location.href='#cases'}
                             onMouseEnter={()=>setHover2(true)}
                             onMouseLeave={()=>setHover2(false)}
                             xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.17157 1.89145C0 3.06302 0 4.94864 0 8.71987V12.7199C0 16.4911 0 18.3767 1.17157 19.5483C2.34315 20.7199 4.22876 20.7199 8 20.7199H12C15.7712 20.7199 17.6569 20.7199 18.8284 19.5483C20 18.3767 20 16.4911 20 12.7199V12.7199V8.71987C20 5.88053 20 4.11005 19.5 2.90843V15.7199C18.5396 15.7199 17.6185 15.3383 16.9393 14.6592L16.1877 13.9075C15.4664 13.1862 15.1057 12.8256 14.6968 12.6736C14.2473 12.5065 13.7527 12.5065 13.3032 12.6736C12.8943 12.8256 12.5336 13.1862 11.8123 13.9075L11.6992 14.0207C11.1138 14.606 10.8212 14.8987 10.5102 14.9533C10.2685 14.9957 10.0197 14.9478 9.81101 14.8186C9.54254 14.6525 9.3795 14.272 9.05341 13.5112L9 13.3865C8.25036 11.6374 7.87554 10.7628 7.22167 10.435C6.89249 10.27 6.52413 10.199 6.1572 10.23C5.42836 10.2915 4.75554 10.9643 3.40989 12.31L1.5 14.2199V1.60727C1.3844 1.69337 1.27519 1.78783 1.17157 1.89145Z" fill="white"/>
                            <path d="M1 8.71982C1 6.80593 1.00212 5.47111 1.13753 4.46398C1.26907 3.48561 1.50966 2.96752 1.87868 2.5985C2.2477 2.22948 2.76579 1.98889 3.74416 1.85735C4.75129 1.72194 6.08611 1.71982 8 1.71982H12C13.9139 1.71982 15.2487 1.72194 16.2558 1.85735C17.2342 1.98889 17.7523 2.22948 18.1213 2.5985C18.4903 2.96751 18.7309 3.48561 18.8625 4.46398C18.9979 5.47111 19 6.80593 19 8.71982V12.7198C19 14.6337 18.9979 15.9685 18.8625 16.9757C18.7309 17.954 18.4903 18.4721 18.1213 18.8411C17.7523 19.2102 17.2342 19.4508 16.2558 19.5823C15.2487 19.7177 13.9139 19.7198 12 19.7198H8C6.08611 19.7198 4.75129 19.7177 3.74416 19.5823C2.76579 19.4508 2.2477 19.2102 1.87868 18.8411C1.50966 18.4721 1.26907 17.954 1.13753 16.9757C1.00212 15.9685 1 14.6337 1 12.7198V8.71982Z" stroke="white" strokeWidth="2"/>
                            <circle cx="13" cy="7.71982" r="2" fill="white"/>
                        </svg>
                        <div className={classes.menuHover} style={{marginTop:'70px',opacity:hover2?1:0,zIndex:hover2?1:-1}}>ПОРТФОЛИО</div>
                        <svg className={classes.iconMenu}
                             onClick={()=>window.location.href='#price'}
                             onMouseEnter={()=>setHover4(true)}
                             onMouseLeave={()=>setHover4(false)}
                             xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.00173786 5.71986H19.9983C19.9862 3.54484 19.8897 2.36693 19.1213 1.59854C18.2426 0.719864 16.8284 0.719864 14 0.719864H6C3.17157 0.719864 1.75736 0.719864 0.87868 1.59854C0.110296 2.36693 0.0138448 3.54484 0.00173786 5.71986ZM20 7.71986H0V9.71986C0 12.5483 0 13.9625 0.87868 14.8412C1.75736 15.7199 3.17157 15.7199 6 15.7199H14C16.8284 15.7199 18.2426 15.7199 19.1213 14.8412C20 13.9625 20 12.5483 20 9.71986V7.71986ZM5 10.7199C4.44772 10.7199 4 11.1676 4 11.7199C4 12.2721 4.44772 12.7199 5 12.7199H5.01C5.56228 12.7199 6.01 12.2721 6.01 11.7199C6.01 11.1676 5.56228 10.7199 5.01 10.7199H5Z" fill="white"/>
                        </svg>
                        <div className={classes.menuHover} style={{marginTop:'130px',opacity:hover4?1:0,zIndex:hover4?1:-1}}>цены</div>
                        <svg className={classes.iconMenu}
                             onClick={()=>window.location.href='#about'}
                             onMouseEnter={()=>setHover3(true)}
                             onMouseLeave={()=>setHover3(false)}
                             xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18 9.71986C18 14.6904 13.9706 18.7199 9 18.7199C4.02944 18.7199 0 14.6904 0 9.71986C0 4.7493 4.02944 0.719864 9 0.719864C13.9706 0.719864 18 4.7493 18 9.71986ZM10 5.71986C10 6.27215 9.55229 6.71986 9 6.71986C8.44771 6.71986 8 6.27215 8 5.71986C8 5.16758 8.44771 4.71986 9 4.71986C9.55229 4.71986 10 5.16758 10 5.71986ZM10 14.7199V8.71986H8V14.7199H10Z" fill="white"/>
                        </svg>
                        <div className={classes.menuHover} style={{marginTop:'190px',opacity:hover3?1:0,zIndex:hover3?1:-1}}>О НАС</div>
                        </MediaQuery>
                </div>
                </MediaQuery>
                <div className={classes.block2_2}>
                    <MediaQuery maxWidth={824}>
                        <div>
                            <div className={classes.iconsBlock}>
                                <svg className={classes.icon} onClick={()=>{window.open("https://t.me/Chat_TDPvideo", "_blank");}} xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.78737 9.47084L15.7493 3.33039C22.398 0.50681 23.7795 0.0163556 24.6799 0.000173324C24.878 -0.00339557 25.3208 0.046715 25.6076 0.284364C25.8499 0.485004 25.9165 0.756143 25.9484 0.946321C25.9804 1.1365 26.02 1.57 25.9884 1.90865C25.6281 5.77381 24.0691 15.1538 23.276 19.483C22.9404 21.3148 22.2801 21.929 21.6399 21.9891C20.2496 22.1197 19.1938 21.0509 17.8473 20.1497L12.5044 16.4853C10.1407 14.895 11.673 14.0208 13.0201 12.5923C13.3726 12.2183 19.4983 6.52939 19.6168 6.01332C19.6312 5.94878 19.6454 5.70825 19.5054 5.58114C19.3655 5.45403 19.1588 5.49803 19.0097 5.5321C18.7984 5.58109 15.4322 7.85276 8.91129 12.3472C7.95581 13.0171 7.09034 13.3436 6.31499 13.3265C5.46015 13.3076 3.81584 12.833 2.59346 12.4273C1.09413 11.9297 -0.0975042 11.6665 0.00630388 10.8214C0.0603147 10.3813 0.654003 9.93118 1.78732 9.47094L1.78737 9.47084Z" fill="url(#paint0_linear_304_1673)"/>
                                    <path className={classes.iconPath} fillRule="evenodd" clipRule="evenodd" d="M1.78737 9.47084L15.7493 3.33039C22.398 0.50681 23.7795 0.0163556 24.6799 0.000173324C24.878 -0.00339557 25.3208 0.046715 25.6076 0.284364C25.8499 0.485004 25.9165 0.756143 25.9484 0.946321C25.9804 1.1365 26.02 1.57 25.9884 1.90865C25.6281 5.77381 24.0691 15.1538 23.276 19.483C22.9404 21.3148 22.2801 21.929 21.6399 21.9891C20.2496 22.1197 19.1938 21.0509 17.8473 20.1497L12.5044 16.4853C10.1407 14.895 11.673 14.0208 13.0201 12.5923C13.3726 12.2183 19.4983 6.52939 19.6168 6.01332C19.6312 5.94878 19.6454 5.70825 19.5054 5.58114C19.3655 5.45403 19.1588 5.49803 19.0097 5.5321C18.7984 5.58109 15.4322 7.85276 8.91129 12.3472C7.95581 13.0171 7.09034 13.3436 6.31499 13.3265C5.46015 13.3076 3.81584 12.833 2.59346 12.4273C1.09413 11.9297 -0.0975042 11.6665 0.00630388 10.8214C0.0603147 10.3813 0.654003 9.93118 1.78732 9.47094L1.78737 9.47084Z" fill="white"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_304_1673" x1="15" y1="2.32458e-07" x2="5.97931" y2="22.5517" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F3E390"/>
                                            <stop offset="1" stopColor="#885C1A"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <svg className={classes.icon} onClick={()=>{window.open("https://www.instagram.com/talib_john?igsh=ZGhxdmdvbnpsd3R4", "_blank");}} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="31px" height="31px">
                                    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" fill="url(#paint0_linear_304_1673)"/>
                                    <path className={classes.iconPath} d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" fill={'#fff'}/>
                                </svg>
                                {false && <svg className={classes.icon} onClick={()=>{window.open("https://youtube.com/@talibjohn?si=Su7_gpaZ5faWjjQn", "_blank");}} xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18" fill="none">
                                    <path d="M25.741 3.88392C25.741 3.88392 25.4871 2.11941 24.7051 1.34463C23.7148 0.32491 22.6078 0.319911 22.1 0.259928C18.4641 -1.43011e-07 13.0051 0 13.0051 0H12.9949C12.9949 0 7.53594 -1.43011e-07 3.9 0.259928C3.39219 0.319911 2.28516 0.32491 1.29492 1.34463C0.512891 2.11941 0.264062 3.88392 0.264062 3.88392C0.264062 3.88392 0 5.95834 0 8.02777V9.96723C0 12.0367 0.258984 14.1111 0.258984 14.1111C0.258984 14.1111 0.512891 15.8756 1.28984 16.6504C2.28008 17.6701 3.58008 17.6351 4.15898 17.7451C6.24102 17.94 13 18 13 18C13 18 18.4641 17.99 22.1 17.7351C22.6078 17.6751 23.7148 17.6701 24.7051 16.6504C25.4871 15.8756 25.741 14.1111 25.741 14.1111C25.741 14.1111 26 12.0417 26 9.96723V8.02777C26 5.95834 25.741 3.88392 25.741 3.88392ZM10.3137 12.3216V5.12857L17.3367 8.73757L10.3137 12.3216Z" fill="url(#paint0_linear_304_1673)"/>
                                    <path className={classes.iconPath} d="M25.741 3.88392C25.741 3.88392 25.4871 2.11941 24.7051 1.34463C23.7148 0.32491 22.6078 0.319911 22.1 0.259928C18.4641 -1.43011e-07 13.0051 0 13.0051 0H12.9949C12.9949 0 7.53594 -1.43011e-07 3.9 0.259928C3.39219 0.319911 2.28516 0.32491 1.29492 1.34463C0.512891 2.11941 0.264062 3.88392 0.264062 3.88392C0.264062 3.88392 0 5.95834 0 8.02777V9.96723C0 12.0367 0.258984 14.1111 0.258984 14.1111C0.258984 14.1111 0.512891 15.8756 1.28984 16.6504C2.28008 17.6701 3.58008 17.6351 4.15898 17.7451C6.24102 17.94 13 18 13 18C13 18 18.4641 17.99 22.1 17.7351C22.6078 17.6751 23.7148 17.6701 24.7051 16.6504C25.4871 15.8756 25.741 14.1111 25.741 14.1111C25.741 14.1111 26 12.0417 26 9.96723V8.02777C26 5.95834 25.741 3.88392 25.741 3.88392ZM10.3137 12.3216V5.12857L17.3367 8.73757L10.3137 12.3216Z" fill="white"/>
                                </svg>}
                                <svg className={classes.icon} onClick={()=>{window.open("https://vk.com/topdayproduction", "_blank");}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.68707 1.68707C0 3.37413 0 6.0894 0 11.52V12.48C0 17.9106 0 20.6259 1.68707 22.313C3.37413 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.313C24 20.6259 24 17.9106 24 12.48V11.52C24 6.0894 24 3.37413 22.313 1.68707C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37413 0 1.68707 1.68707ZM4.05006 7.30005C4.18006 13.54 7.30005 17.2901 12.7701 17.2901H13.0801V13.72C15.0901 13.92 16.61 15.3901 17.22 17.2901H20.06C19.28 14.4501 17.2299 12.8801 15.95 12.2801C17.2299 11.5401 19.0299 9.74005 19.4599 7.30005H16.8799C16.3199 9.28005 14.6601 11.08 13.0801 11.25V7.30005H10.5V14.22C8.9 13.8201 6.88005 11.88 6.79005 7.30005H4.05006Z" fill="url(#paint0_linear_304_1673)"/>
                                    <path className={classes.iconPath} fillRule="evenodd" clipRule="evenodd" d="M1.68707 1.68707C0 3.37413 0 6.0894 0 11.52V12.48C0 17.9106 0 20.6259 1.68707 22.313C3.37413 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.313C24 20.6259 24 17.9106 24 12.48V11.52C24 6.0894 24 3.37413 22.313 1.68707C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37413 0 1.68707 1.68707ZM4.05006 7.30005C4.18006 13.54 7.30005 17.2901 12.7701 17.2901H13.0801V13.72C15.0901 13.92 16.61 15.3901 17.22 17.2901H20.06C19.28 14.4501 17.2299 12.8801 15.95 12.2801C17.2299 11.5401 19.0299 9.74005 19.4599 7.30005H16.8799C16.3199 9.28005 14.6601 11.08 13.0801 11.25V7.30005H10.5V14.22C8.9 13.8201 6.88005 11.88 6.79005 7.30005H4.05006Z" fill="white"/>
                                </svg>
                            </div>
                            <MediaQuery maxWidth={824}>
                                <div className={classes.menuSmall} onClick={()=>setOpenMenu(!openMenu)}>
                                    <div className={classes.textMenu}>МЕНЮ</div>
                                    <svg className={classes.openMenu} style={{opacity:openMenu?0:1}} onClick={()=>setOpenMenu(!openMenu)}  xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H17V2H0V0ZM0 7H17V9H0V7ZM17 14H0V16H17V14Z" fill="white"/>
                                    </svg>
                                    <svg className={classes.openMenu+' '+classes.openMenu2} style={{opacity:openMenu?1:0}} onClick={()=>setOpenMenu(!openMenu)} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5971 0.418421C16.0604 -0.139494 15.1902 -0.139494 14.6536 0.418421L8.49977 6.8157L2.346 0.418445C1.80932 -0.13947 0.939191 -0.13947 0.40251 0.418445C-0.13417 0.976361 -0.13417 1.88092 0.40251 2.43884L6.55628 8.8361L1.04913 14.5612C0.512445 15.1191 0.512446 16.0236 1.04913 16.5815C1.58581 17.1395 2.45594 17.1395 2.99262 16.5815L8.49977 10.8565L14.0069 16.5816C14.5436 17.1395 15.4138 17.1395 15.9504 16.5816C16.4871 16.0237 16.4871 15.1191 15.9504 14.5612L10.4433 8.83609L16.5971 2.43881C17.1337 1.8809 17.1337 0.976336 16.5971 0.418421Z" fill="white"/>
                                    </svg>
                                </div>
                            </MediaQuery>
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={825}>
                        <div className={classes.iconsBlock}>
                            {false && <svg className={classes.icon} onClick={()=>{window.open("https://youtube.com/@talibjohn?si=Su7_gpaZ5faWjjQn", "_blank");}} xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18" fill="none">
                                <path d="M25.741 3.88392C25.741 3.88392 25.4871 2.11941 24.7051 1.34463C23.7148 0.32491 22.6078 0.319911 22.1 0.259928C18.4641 -1.43011e-07 13.0051 0 13.0051 0H12.9949C12.9949 0 7.53594 -1.43011e-07 3.9 0.259928C3.39219 0.319911 2.28516 0.32491 1.29492 1.34463C0.512891 2.11941 0.264062 3.88392 0.264062 3.88392C0.264062 3.88392 0 5.95834 0 8.02777V9.96723C0 12.0367 0.258984 14.1111 0.258984 14.1111C0.258984 14.1111 0.512891 15.8756 1.28984 16.6504C2.28008 17.6701 3.58008 17.6351 4.15898 17.7451C6.24102 17.94 13 18 13 18C13 18 18.4641 17.99 22.1 17.7351C22.6078 17.6751 23.7148 17.6701 24.7051 16.6504C25.4871 15.8756 25.741 14.1111 25.741 14.1111C25.741 14.1111 26 12.0417 26 9.96723V8.02777C26 5.95834 25.741 3.88392 25.741 3.88392ZM10.3137 12.3216V5.12857L17.3367 8.73757L10.3137 12.3216Z" fill="url(#paint0_linear_304_1673)"/>
                                <path className={classes.iconPath} d="M25.741 3.88392C25.741 3.88392 25.4871 2.11941 24.7051 1.34463C23.7148 0.32491 22.6078 0.319911 22.1 0.259928C18.4641 -1.43011e-07 13.0051 0 13.0051 0H12.9949C12.9949 0 7.53594 -1.43011e-07 3.9 0.259928C3.39219 0.319911 2.28516 0.32491 1.29492 1.34463C0.512891 2.11941 0.264062 3.88392 0.264062 3.88392C0.264062 3.88392 0 5.95834 0 8.02777V9.96723C0 12.0367 0.258984 14.1111 0.258984 14.1111C0.258984 14.1111 0.512891 15.8756 1.28984 16.6504C2.28008 17.6701 3.58008 17.6351 4.15898 17.7451C6.24102 17.94 13 18 13 18C13 18 18.4641 17.99 22.1 17.7351C22.6078 17.6751 23.7148 17.6701 24.7051 16.6504C25.4871 15.8756 25.741 14.1111 25.741 14.1111C25.741 14.1111 26 12.0417 26 9.96723V8.02777C26 5.95834 25.741 3.88392 25.741 3.88392ZM10.3137 12.3216V5.12857L17.3367 8.73757L10.3137 12.3216Z" fill="white"/>

                            </svg>}
                            <svg className={classes.icon} onClick={()=>{window.open("https://vk.com/topdayproduction", "_blank");}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.68707 1.68707C0 3.37413 0 6.0894 0 11.52V12.48C0 17.9106 0 20.6259 1.68707 22.313C3.37413 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.313C24 20.6259 24 17.9106 24 12.48V11.52C24 6.0894 24 3.37413 22.313 1.68707C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37413 0 1.68707 1.68707ZM4.05006 7.30005C4.18006 13.54 7.30005 17.2901 12.7701 17.2901H13.0801V13.72C15.0901 13.92 16.61 15.3901 17.22 17.2901H20.06C19.28 14.4501 17.2299 12.8801 15.95 12.2801C17.2299 11.5401 19.0299 9.74005 19.4599 7.30005H16.8799C16.3199 9.28005 14.6601 11.08 13.0801 11.25V7.30005H10.5V14.22C8.9 13.8201 6.88005 11.88 6.79005 7.30005H4.05006Z" fill="url(#paint0_linear_304_1673)"/>
                                <path className={classes.iconPath} fillRule="evenodd" clipRule="evenodd" d="M1.68707 1.68707C0 3.37413 0 6.0894 0 11.52V12.48C0 17.9106 0 20.6259 1.68707 22.313C3.37413 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.313C24 20.6259 24 17.9106 24 12.48V11.52C24 6.0894 24 3.37413 22.313 1.68707C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37413 0 1.68707 1.68707ZM4.05006 7.30005C4.18006 13.54 7.30005 17.2901 12.7701 17.2901H13.0801V13.72C15.0901 13.92 16.61 15.3901 17.22 17.2901H20.06C19.28 14.4501 17.2299 12.8801 15.95 12.2801C17.2299 11.5401 19.0299 9.74005 19.4599 7.30005H16.8799C16.3199 9.28005 14.6601 11.08 13.0801 11.25V7.30005H10.5V14.22C8.9 13.8201 6.88005 11.88 6.79005 7.30005H4.05006Z" fill="white"/>
                                <defs>
                                    <linearGradient id="paint0_linear_304_1673" x1="15" y1="2.32458e-07" x2="5.97931" y2="22.5517" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F3E390"/>
                                        <stop offset="1" stopColor="#885C1A"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg className={classes.icon} onClick={()=>{window.open("https://www.instagram.com/talib_john?igsh=ZGhxdmdvbnpsd3R4", "_blank");}} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="31px" height="31px">
                                <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" fill="url(#paint0_linear_304_1673)"/>
                                <path className={classes.iconPath} d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" fill={'#fff'}/>
                            </svg>
                            <svg className={classes.icon} onClick={()=>{window.open("https://t.me/Chat_TDPvideo", "_blank");}} xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.78737 9.47084L15.7493 3.33039C22.398 0.50681 23.7795 0.0163556 24.6799 0.000173324C24.878 -0.00339557 25.3208 0.046715 25.6076 0.284364C25.8499 0.485004 25.9165 0.756143 25.9484 0.946321C25.9804 1.1365 26.02 1.57 25.9884 1.90865C25.6281 5.77381 24.0691 15.1538 23.276 19.483C22.9404 21.3148 22.2801 21.929 21.6399 21.9891C20.2496 22.1197 19.1938 21.0509 17.8473 20.1497L12.5044 16.4853C10.1407 14.895 11.673 14.0208 13.0201 12.5923C13.3726 12.2183 19.4983 6.52939 19.6168 6.01332C19.6312 5.94878 19.6454 5.70825 19.5054 5.58114C19.3655 5.45403 19.1588 5.49803 19.0097 5.5321C18.7984 5.58109 15.4322 7.85276 8.91129 12.3472C7.95581 13.0171 7.09034 13.3436 6.31499 13.3265C5.46015 13.3076 3.81584 12.833 2.59346 12.4273C1.09413 11.9297 -0.0975042 11.6665 0.00630388 10.8214C0.0603147 10.3813 0.654003 9.93118 1.78732 9.47094L1.78737 9.47084Z" fill="url(#paint0_linear_304_1673)"/>
                                <path className={classes.iconPath} fillRule="evenodd" clipRule="evenodd" d="M1.78737 9.47084L15.7493 3.33039C22.398 0.50681 23.7795 0.0163556 24.6799 0.000173324C24.878 -0.00339557 25.3208 0.046715 25.6076 0.284364C25.8499 0.485004 25.9165 0.756143 25.9484 0.946321C25.9804 1.1365 26.02 1.57 25.9884 1.90865C25.6281 5.77381 24.0691 15.1538 23.276 19.483C22.9404 21.3148 22.2801 21.929 21.6399 21.9891C20.2496 22.1197 19.1938 21.0509 17.8473 20.1497L12.5044 16.4853C10.1407 14.895 11.673 14.0208 13.0201 12.5923C13.3726 12.2183 19.4983 6.52939 19.6168 6.01332C19.6312 5.94878 19.6454 5.70825 19.5054 5.58114C19.3655 5.45403 19.1588 5.49803 19.0097 5.5321C18.7984 5.58109 15.4322 7.85276 8.91129 12.3472C7.95581 13.0171 7.09034 13.3436 6.31499 13.3265C5.46015 13.3076 3.81584 12.833 2.59346 12.4273C1.09413 11.9297 -0.0975042 11.6665 0.00630388 10.8214C0.0603147 10.3813 0.654003 9.93118 1.78732 9.47094L1.78737 9.47084Z" fill="white"/>
                            </svg>
                        </div>
                    </MediaQuery>

                    <MediaQuery minWidth={1470}>
                        <a className={classes.tel} href={'tel:+7 (937) 982-98-66'}>+7 937 982 98 66</a>
                        <a className={classes.email} href={'mailto:topdayproduction@mail.ru'}>topdayproduction@mail.ru</a>
                    </MediaQuery>
                    <MediaQuery maxWidth={1469} minWidth={825}>
                        <Call click={popup}/>
                    </MediaQuery>
                    {openMenu &&
                        <div className={classes.fon} onClick={()=>setOpenMenu(false)}>
                            <div className={classes.menu2} onClick={e=>e.stopPropagation()}>
                                {items.map(item =>
                                    <div key={item.id} className={classes.menuItem} onClick={()=>{window.location.href=item.href; setOpenMenu(false)}}>{item.text}</div>
                                )}
                                <a className={classes.tel2} href={'tel:+7(937)982-98-66'}>+7 937 982 98 66</a>
                                <a className={classes.email2} href={'mailto:topdayproduction@mail.ru'}>topdayproduction@mail.ru</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Menu;