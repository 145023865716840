import React, {useEffect, useRef, useState} from 'react';
import classes from "./photoBlock.module.css";
import Close from "../close/close";
import {useInView} from "react-intersection-observer";
import MediaQuery from "react-responsive";

const PhotoBlock = ({photo}) => {
    const [open, setOpen] = useState(false)
    const [style, setStyle] = useState([{},{}])
    const ref2 = useRef(null)
    const {ref, inView} = useInView({
        threshold:.2,
        triggerOnce:true
    })
    const [view, setView] = useState(false)

    useEffect(()=>{
        setView(inView)
    },[inView])

    useEffect(()=>{
        setStyle([
            {
                width: 'calc(100% - 40px)',
                height: 'calc(100% - 40px)',
                left: '0',
                top: '0',
                zIndex: 3,
                opacity: 1
            },
            {
                width: ref2?.current?.width,
                height:  ref2?.current?.height,
                left: ref2?.current?.getBoundingClientRect().left,
                top: ref2?.current?.getBoundingClientRect().top,
                zIndex: -1,
                opacity: 0
            }
        ])
    },[])

    const handleScroll = () => {
        setStyle([
            {
                width: 'calc(100% - 40px)',
                height: 'calc(100% - 40px)',
                left: '0',
                top: '0',
                zIndex: 3,
                opacity: 1
            },
            {
                width: ref2?.current?.width,
                height:  ref2?.current?.height,
                left: ref2?.current?.getBoundingClientRect().left,
                top: ref2?.current?.getBoundingClientRect().top,
                zIndex: -1,
                opacity: 0
            }
        ])
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            <div className={classes._} onClick={()=>setOpen(true)} ref={ref}>
                <MediaQuery minWidth={825}>
                    <div className={classes.fon} style={open?{zIndex:2, opacity:1}:{zIndex:-1, opacity:0}} onClick={()=>setOpen(false)}></div>
                    <div className={classes.open} style={style[open?0:1]} onClick={(e)=>{e.stopPropagation();setOpen(false)}}>
                        <div className={classes.photoBlock}>
                            <img src={photo} alt={''} className={classes.openPhoto}/>
                        </div>
                        {false && <div className={classes.closeBlock}>
                            <Close click={()=>setOpen(false)} />
                        </div>}
                    </div>
                </MediaQuery>
                <img ref={ref2} src={photo} alt={''} className={classes.photo+' '+(view && classes.view)}/>
            </div>
            <MediaQuery maxWidth={824}>
                <div className={classes.fon} style={open?{zIndex:2, opacity:1}:{zIndex:-1, opacity:0}} onClick={()=>setOpen(false)}></div>
                <div className={classes.open} style={style[open?0:1]} onClick={(e)=>{e.stopPropagation();setOpen(false)}}>
                    <div className={classes.photoBlock}>
                        <img src={photo} alt={''} className={classes.openPhoto}/>
                    </div>
                </div>
            </MediaQuery>
        </>
    );
};

export default PhotoBlock;