import React, {useState} from 'react';
import classes from "./block10.module.css";
import logo from '../../image/logo.png'
import Input from "../input/input";
import Button from "../button/button";
import MediaQuery from "react-responsive";
import {new_client} from "../../api";

const Block10 = () => {
    const [format, setFormat] = useState('')
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')

    async function click(){
        await new_client(name, phone, null, format)
        setFormat('')
        setPhone('')
        setName('')
    }

    return (
        <div className={classes._}>
            <div className={classes.block1}>
                <div className={classes.block}>
                    <div className={classes.text2}>Можете задать любой свой вопрос и мы свяжемся с Вами в ближайшее время:</div>
                    <div className={classes.form}>
                        <MediaQuery minWidth={435}>
                            <Input placeholder={'Ваше Имя'} value={name} setValue={setName} style={{marginTop:'2px'}}/>
                            <Input placeholder={'Телефон'} value={phone} setValue={setPhone} style={{marginTop:'2px'}}/>
                            <Input placeholder={'Ваш вопрос'} value={format} setValue={setFormat} style={{marginTop:'2px'}}/>
                        </MediaQuery>
                        <MediaQuery maxWidth={434}>
                            <Input placeholder={'Ваше Имя'} value={name} setValue={setName} style={{width:'100%'}}/>
                            <Input placeholder={'Телефон'} value={phone} setValue={setPhone} style={{width:'100%'}}/>
                            <Input placeholder={'Ваш вопрос'} value={format} setValue={setFormat} style={{width:'100%'}}/>
                        </MediaQuery>
                        <div>
                            <Button text={'Отправить'} click={click}/>
                            <div className={classes.text3}>Нажимая на кнопку, я даю согласие на обработку своих
                                персональных данных.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.block2}>
                <div className={classes.block2_2}>
                    <div className={classes.text1}>all rights reserved 2024</div>
                    <img alt={''} className={classes.logo} src={logo}/>
                </div>
            </div>
        </div>
    );
};

export default Block10;