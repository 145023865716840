import React from 'react';
import classes from "./call.module.css";

const Call = ({click, style}) => {
    return (
        <div className={classes._} onClick={click} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path d="M19.989 14.1182L15.0424 11.9017L15.0288 11.8954C14.772 11.7855 14.4918 11.7414 14.2137 11.7671C13.9356 11.7928 13.6683 11.8874 13.4359 12.0424C13.4085 12.0604 13.3823 12.0801 13.3572 12.1012L10.8014 14.2799C9.18232 13.4935 7.51071 11.8345 6.72426 10.2363L8.90617 7.64176C8.92717 7.61551 8.94712 7.58926 8.96602 7.56091C9.11767 7.32918 9.20968 7.06354 9.23386 6.78765C9.25805 6.51177 9.21365 6.23418 9.10462 5.97959V5.967L6.88176 1.012C6.73763 0.679422 6.48982 0.402377 6.1753 0.22222C5.86078 0.0420629 5.49643 -0.0315438 5.13665 0.0123876C3.71385 0.199612 2.40786 0.898353 1.4626 1.97811C0.517335 3.05786 -0.002563 4.44479 9.50114e-06 5.87984C9.50114e-06 14.2169 6.78306 21 15.1201 21C16.5552 21.0026 17.9421 20.4827 19.0218 19.5374C20.1016 18.5921 20.8003 17.2861 20.9876 15.8633C21.0316 15.5037 20.9581 15.1394 20.7782 14.8249C20.5982 14.5104 20.3214 14.2625 19.989 14.1182ZM15.1201 19.32C11.5568 19.3161 8.1405 17.8988 5.62083 15.3792C3.10117 12.8595 1.68391 9.44319 1.68002 5.87984C1.67607 4.8545 2.04548 3.86277 2.71925 3.08987C3.39303 2.31697 4.3251 1.81574 5.3414 1.6798C5.34098 1.68399 5.34098 1.68821 5.3414 1.6924L7.54641 6.62745L5.37605 9.22518C5.35402 9.25052 5.33401 9.27756 5.3162 9.30603C5.15819 9.54849 5.06549 9.82767 5.0471 10.1165C5.0287 10.4053 5.08523 10.694 5.2112 10.9545C6.1625 12.9002 8.12287 14.8459 10.0895 15.7961C10.352 15.9209 10.6423 15.9755 10.9322 15.9545C11.2221 15.9335 11.5015 15.8377 11.7433 15.6764C11.7703 15.6583 11.7962 15.6386 11.821 15.6176L14.3736 13.4399L19.3086 15.6502C19.3086 15.6502 19.317 15.6502 19.3201 15.6502C19.1859 16.6679 18.6854 17.6019 17.9123 18.2773C17.1393 18.9528 16.1467 19.3234 15.1201 19.32Z" fill="white"/>
            </svg>
        </div>
    );
};

export default Call;