import React, {useEffect, useRef, useState} from 'react';
import classes from "./videoBlock.module.css";
import Close from "../close/close";
import {useInView} from "react-intersection-observer";
import play from '../../image/play.png'
import MediaQuery from "react-responsive";

const VideoBlock = ({video}) => {
    const [open, setOpen] = useState(false)
    const [style, setStyle] = useState([{},{}])
    const ref2 = useRef(null)
    const {ref, inView} = useInView({
        threshold:.2,
        triggerOnce:true
    })
    const [view, setView] = useState(false)
    const [music, setMusic] = useState(false)
    const [autoplay, setAutoplay] = useState(true)
    const refVideo = useRef()
    const refVideo2 = useRef()

    useEffect(()=>{
        setView(inView)
    },[inView])

    useEffect(()=>{
        setStyle([
            {
                width: 'calc(100% - 40px)',
                height: 'calc(100vh - 40px)',
                left: '20px',
                top: '20px',
                zIndex: 3,
                opacity: 1
            },
            {
                width: ref2?.current?.width,
                height:  ref2?.current?.height,
                left: ref2?.current?.getBoundingClientRect().left,
                top: ref2?.current?.getBoundingClientRect().top,
                zIndex: -1,
                opacity: 0
            }
        ])
    },[])

    const handleScroll = () => {
        setStyle([
            {
                width: 'calc(100% - 40px)',
                height: 'calc(100vh - 40px)',
                left: '0',
                top: '0',
                zIndex: 3,
                opacity: 1
            },
            {
                width: ref2?.current?.width,
                height:  ref2?.current?.height,
                left: ref2?.current?.getBoundingClientRect().left,
                top: ref2?.current?.getBoundingClientRect().top,
                zIndex: -1,
                opacity: 0
            }
        ])
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    useEffect(()=>{
        if(open){
            setMusic(true)
            refVideo?.current?.play()
            refVideo.current.currentTime = refVideo2.current.currentTime
        }else{
            setMusic(false)
            refVideo?.current?.pause()
            refVideo2.current.currentTime = refVideo.current.currentTime
        }
    },[open])

    useEffect(()=>{
        setTimeout(()=>{ refVideo?.current?.pause(); refVideo2?.current?.pause()}, 2000)
    },[])


    return (
        <>
            <div className={classes._} onClick={()=>setOpen(true)} ref={ref}>
                <div className={classes.fon} style={open?{zIndex:2, opacity:1}:{zIndex:-1, opacity:0}} onClick={()=>setOpen(false)}></div>
                <div className={classes.open} style={style[open?0:1]} onClick={(e)=>{e.stopPropagation();setOpen(false)}}>
                    <div className={classes.photoBlock}>
                        <video
                            muted={!music}
                            loop
                            playsInline
                            className={classes.openPhoto}
                            autoPlay={autoplay}
                            ref={refVideo}>
                            <source
                                src={video}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
                <img ref={ref2} src={video} alt={''} className={classes.photo}/>
                <video loop
                       muted={true}
                       playsInline
                       className={classes.photo+' '+(view && classes.view)}
                       autoPlay={autoplay}
                       ref={refVideo2}
                       onMouseMove={(ev)=> ev.target.play()}
                       onMouseOut={(ev)=> ev.target.pause()}
                >
                    <source
                        src={video}
                        type="video/mp4"
                    />
                </video>
                <MediaQuery maxWidth={824}>
                    <div className={classes.playBlock}>
                        <img src={play} alt={''} className={classes.play}/>
                    </div>
                </MediaQuery>
            </div>
        </>
    );
};

export default VideoBlock;