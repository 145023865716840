import React from 'react';
import classes from "./block9.module.css";
import PhotoBlock from "../photoBlock/photoBlock";
import photo1 from "../../image/07.jpg";
import photo2 from "../../image/08.jpg";
import photo3 from "../../image/09.jpg";
import photo4 from "../../image/10.jpg";
import photo5 from "../../image/11.jpg";
import photo6 from "../../image/12.jpg";
import MediaQuery from "react-responsive";
import photo1M from "../../image/07M.jpg";
import photo2M from "../../image/08M.jpg";
import photo3M from "../../image/09M.jpg";
import photo4M from "../../image/10M.jpg";
import photo5M from "../../image/11M.jpg";
import photo6M from "../../image/12M.jpg";

const Block9 = () => {
    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.photos}>
                    <MediaQuery minWidth={825}>
                        <PhotoBlock photo={photo1}/>
                        <PhotoBlock photo={photo2}/>
                        <PhotoBlock photo={photo3}/>
                    </MediaQuery>
                    <MediaQuery maxWidth={824}>
                        <PhotoBlock photo={photo1M}/>
                        <PhotoBlock photo={photo2M}/>
                        <PhotoBlock photo={photo3M}/>
                    </MediaQuery>
                </div>
                <div className={classes.photos}>
                    <MediaQuery minWidth={825}>
                        <PhotoBlock photo={photo4}/>
                        <PhotoBlock photo={photo5}/>
                        <PhotoBlock photo={photo6}/>
                    </MediaQuery>
                    <MediaQuery maxWidth={824}>
                        <PhotoBlock photo={photo4M}/>
                        <PhotoBlock photo={photo5M}/>
                        <PhotoBlock photo={photo6M}/>
                    </MediaQuery>
                </div>
            </div>
        </div>
    );
};

export default Block9;