import React from 'react';
import classes from "./block4.module.css";
import MediaQuery from "react-responsive";

const Block4 = () => {
    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.text1}>Вы наш <span className={classes.text2_1}>клиент</span>, <MediaQuery maxWidth={619}><br/></MediaQuery>если вы -</div>
                <div className={classes.block2}>
                    <div className={classes.block2_1}>
                        <div className={classes.text2}>Event-Aгентства<br/>и корпоративные<br/>клиенты,</div>
                        <div className={classes.text3}>которые хотят необычную, запоминающуюся услугу в качестве Welcome-активности <MediaQuery minWidth={1309}><br/></MediaQuery>на мероприятии, результат которой останется в памяти компании и гостей навсегда.</div>
                    </div>
                    <div className={classes.block2_1}>
                        <div className={classes.text2}>Медийные<br/>и творческие<br/>личности,</div>
                        <div className={classes.text3}>для которых важно поразить и удивить гостей собственного мероприятия <MediaQuery minWidth={1309}><br/></MediaQuery>и вовлечь их в творческую имиджевую активность.</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Block4;