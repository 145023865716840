import React, {useState} from 'react';
import classes from "./popup.module.css";
import Close from "../close/close";
import Input from "../input/input";
import Button from "../button/button";
import {new_client} from "../../api";

const Popup = ({close}) => {
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')

    async function click(){
        await new_client(name,phone)
        setName('')
        setPhone('')
    }


    return (
        <div className={classes._}>
            <div className={classes.block}>
                <Close click={close}/>
                <div className={classes.popup}>
                    <div className={classes.text1}>Оставьте свой<br/>контакт</div>
                    <div className={classes.text2}>Мы перезвоним Вам в ближайшее время и ответим на все вопросы.</div>
                    <div className={classes.form}>
                        <Input placeholder={'Ваш телефон'} value={phone} setValue={setPhone} style={{maxWidth:'370px', width:'90%'}}/>
                        <Input placeholder={'Ваше Имя'} value={name} setValue={setName} style={{maxWidth:'370px', width:'90%'}}/>
                        <div className={classes.button}>
                            <Button text={'Перезвоните мне'} click={click}/>
                        </div>
                    </div>
                    <div className={classes.text3}>Нажимая на кнопку, я даю согласие на обработку своих персональных данных.</div>
                </div>
            </div>
        </div>
    );
};

export default Popup;