import React from 'react';
import classes from "./close.module.css";

const Close = ({click, style}) => {
    return (
        <div className={classes._} onClick={click} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5029 0.516892C19.8399 -0.172298 18.7651 -0.172297 18.1021 0.516892L10.5 8.41975L2.898 0.516929C2.23505 -0.172261 1.16018 -0.172261 0.497219 0.516929C-0.165739 1.20612 -0.16574 2.32352 0.497218 3.01271L8.09925 10.9155L1.29661 17.9873C0.633657 18.6765 0.633656 19.7939 1.29661 20.4831C1.95957 21.1723 3.03444 21.1723 3.6974 20.4831L10.5 13.4113L17.3027 20.4831C17.9657 21.1723 19.0405 21.1723 19.7035 20.4831C20.3664 19.794 20.3664 18.6766 19.7035 17.9874L12.9008 10.9155L20.5029 3.01267C21.1658 2.32348 21.1658 1.20608 20.5029 0.516892Z" fill="white"/>
            </svg>
        </div>
    );
};

export default Close;