import React from 'react';
import classes from "./block8.module.css";
import fon5 from '../../image/fon5.png'
import MediaQuery from "react-responsive";

const Block8 = () => {
    return (
        <div className={classes._} id='about'>
            <div className={classes.block}>
                <img className={classes.fon} alt={''} src={fon5}/>
                <div className={classes.texts}>
                    <div className={classes.text1}>Почему <MediaQuery maxWidth={629}><br/></MediaQuery>я создал<MediaQuery maxWidth={629}><br/></MediaQuery> этот проект?</div>
                    <div className={classes.text2}>Я хотел вдохнуть новую жизнь в видеосъемки коммерческих проектов.</div>
                    <div className={classes.text3}>Мы выделяем не только главного героя или героиню, но и показываем их с настоящими эмоциями, с теми людьми, кто их окружает в этот день. Мы отказываемся от баннеров и фото-зон. Наша цель – показать людей в моменте, где они находятся и что вокруг происходит.</div>
                    <div className={classes.text3}>Это делает наши видео и фотоиллюстрации<br/>более ценными и настоящими.</div>
                </div>
            </div>
        </div>
    );
};

export default Block8;