import React from 'react';
import classes from "./button.module.css";

const Button = ({text, click, style}) => {
    return (
        <div className={classes._} onClick={click} style={style}>
            {text}
        </div>
    );
};

export default Button;