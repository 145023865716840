import React, {useEffect, useState} from 'react';
import classes from "./input.module.css";

const Input = ({value, setValue, placeholder, style}) => {
    const [newValue, setNewValue] = useState(value)
    useEffect(()=>{
        setNewValue(value)
    },[value])

    return (
        <input className={classes._} style={style} value={newValue} onChange={e=>{setValue(e.target.value)}} placeholder={placeholder}/>
    );
};

export default Input;