import React, {useEffect, useState} from 'react';
import classes from "./loading.module.css";
import logo from '../../image/logo.png'

const Loading = ({loading}) => {
    const [load, setLoad] = useState(true)
    const [load2, setLoad2] = useState(true)

    useEffect(()=>{
        if(!loading){
            setTimeout(()=>setLoad(false),1000)
            setTimeout(()=>setLoad2(false),3000)
        }
    },[loading])

    return (
        <div className={classes._} style={{opacity:load?1:0, zIndex:load2?10:-10}}>
            <img src={logo} alt={''} className={classes.icon}/>
        </div>
    );
};

export default Loading;