import React from 'react';
import classes from "./list.module.css";

const List = () => {
    const textHeader = [
        {id:0, text:'Рекламные ролики любой продукции'},
        {id:1, text:'Выставки'},
        {id:2, text:'Выпускные'},
        {id:3, text:'Свадьбы'},
        {id:4, text:'Отели, рестораны и любые предприятия сегмента HoReCa'},
        {id:5, text:'Конференции и форумы'},
        {id:6, text:'Модельные видео'},
        {id:7, text:'Дни Рождения'}
    ]

    return (
        <div className={classes._}>
            {textHeader.map(item=>
                <div className={classes.block} key={item.id}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" style={{minWidth:'10px'}} viewBox="0 0 10 10" fill="none">
                        <path d="M5 0.794922L6.12257 4.24984H9.75528L6.81636 6.38509L7.93893 9.84001L5 7.70475L2.06107 9.84001L3.18364 6.38509L0.244718 4.24984H3.87743L5 0.794922Z" fill="url(#paint0_linear_379_2057)" fillOpacity="0.6"/>
                        <defs>
                            <linearGradient id="paint0_linear_379_2057" x1="5.08962" y1="0.794921" x2="5.08962" y2="10.6156" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#F7E4B5"/>
                                <stop offset="0.209457" stopColor="#D0B265"/>
                                <stop offset="0.574381" stopColor="#AD8D39"/>
                                <stop offset="0.941322" stopColor="#664A1F"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className={classes.text}>{item.text}</div>
                </div>
            )}
        </div>
    );
};

export default List;