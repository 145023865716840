import React from 'react';
import classes from "./block3.module.css";
import PhotoBlock from "../photoBlock/photoBlock";
import photo1 from '../../image/01.jpg'
import photo2 from '../../image/02.jpg'
import photo3 from '../../image/03.jpg'
import photo4 from '../../image/04.jpg'
import photo5 from '../../image/05.jpg'
import photo6 from '../../image/06.jpg'
import photo1M from '../../image/01M.jpg'
import photo2M from '../../image/02M.jpg'
import photo3M from '../../image/03M.jpg'
import photo4M from '../../image/04M.jpg'
import photo5M from '../../image/05M.jpg'
import photo6M from '../../image/06M.jpg'
import VideoBlock from "../videoBlock/videoBlock";
import video1 from '../../video/video1.m4v'
import video2 from '../../video/video2.mov'
import video3 from '../../video/video3.m4v'
import video4 from '../../video/video4.m4v'
import video1M from '../../video/video1M.m4v'
import video2M from '../../video/video2M.mov'
import video3M from '../../video/video3M.m4v'
import video4M from '../../video/video4M.m4v'
import MediaQuery from "react-responsive";

const Block3 = () => {
    return (
        <div className={classes._} id='cases'>
            <div className={classes.block}>
                <MediaQuery minWidth={825}>
                    <VideoBlock video={video1}/>
                    <VideoBlock video={video2}/>
                    <div className={classes.photos}>
                        <PhotoBlock photo={photo1}/>
                        <PhotoBlock photo={photo2}/>
                        <PhotoBlock photo={photo3}/>
                    </div>
                    <VideoBlock video={video3}/>
                    <VideoBlock video={video4}/>
                    <div className={classes.photos}>
                        <PhotoBlock photo={photo4}/>
                        <PhotoBlock photo={photo5}/>
                        <PhotoBlock photo={photo6}/>
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth={824}>
                    <VideoBlock video={video1M}/>
                    <VideoBlock video={video2M}/>
                    <div className={classes.photos}>
                        <PhotoBlock photo={photo1M}/>
                        <PhotoBlock photo={photo2M}/>
                        <PhotoBlock photo={photo3M}/>
                    </div>
                    <VideoBlock video={video3M}/>
                    <VideoBlock video={video4M}/>
                    <div className={classes.photos}>
                        <PhotoBlock photo={photo4M}/>
                        <PhotoBlock photo={photo5M}/>
                        <PhotoBlock photo={photo6M}/>
                    </div>
                </MediaQuery>
            </div>
        </div>
    );
};

export default Block3;