import React from 'react';
import classes from "./block6.module.css";
import MediaQuery from "react-responsive";

const Block6 = () => {
    const text1 = [
        {id:1, text:'Первоначальный брифинг<br/>c Заказчиком, выявление нюансов<br/>и пожеланий', textAll:'Первоначальный брифинг c Заказчиком, выявление нюансов и пожеланий'},
        {id:2, text:'Составление Коммерческого Предложения и уникального сценария предстоящей съемки',textAll:'Составление Коммерческого Предложения и уникального сценария предстоящей съемки'},
        {id:3, text:'Внесение корректив и финальное согласование Коммерческого Предложения и сценария',textAll:'Внесение корректив и финальное согласование Коммерческого Предложения и сценария'},
        {id:4, text:'Выезд на площадку,<br/>согласование технических нюансов и производство замеров', text2:'* возможно без участия Заказчика',textAll:'Выезд на площадку, согласование технических нюансов и производство замеров'}
    ]
    const text2 = [
        {id:1, text:'В день мероприятия:<br/>монтаж на площадке, далее<br/>съемочный процесс',textAll:'В день мероприятия: монтаж на площадке, далее съемочный процесс'},
        {id:2, text:'Частичное предоставление отснятых материалов прямо во время мероприятия', text2: '* возможность демонстрация частично готового ролика перед завершением мероприятия', textAll:'Частичное предоставление отснятых материалов прямо во время мероприятия'},
        {id:3, text:'Демонтаж',textAll:'Демонтаж'},
        {id:4}
    ]

    return (
        <div className={classes._} id='work'>
            <div className={classes.block}>
                <div className={classes.text1}>
                    Как <span className={classes.text2}>мы</span> работаем
                </div>
                <div className={classes.texts}>
                    <div className={classes.textBlock}>
                        <div className={classes.numberBlock}>
                            <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.156 22.644C11.156 24.2733 11.52 25.4 12.248 26.024C13.0107 26.6133 14.3107 26.908 16.148 26.908V28C12.404 27.8613 10.012 27.792 8.972 27.792C8.00133 27.792 5.748 27.844 2.212 27.948L0.964 28V26.908C2.80133 26.908 4.17067 26.596 5.072 25.972C6.008 25.348 6.476 24.2387 6.476 22.644V10.06C6.476 8.396 6.30267 7.18267 5.956 6.42C5.60933 5.62267 5.02 5.10267 4.188 4.86C3.39067 4.58267 2.14267 4.444 0.444 4.444V3.3C2.97467 3.23067 5.05467 3.00533 6.684 2.624C8.348 2.24267 9.83867 1.584 11.156 0.647999V22.644Z" fill="#63573A"/>
                                <path d="M11.156 22.644C11.156 24.2733 11.52 25.4 12.248 26.024C13.0107 26.6133 14.3107 26.908 16.148 26.908V28C12.404 27.8613 10.012 27.792 8.972 27.792C8.00133 27.792 5.748 27.844 2.212 27.948L0.964 28V26.908C2.80133 26.908 4.17067 26.596 5.072 25.972C6.008 25.348 6.476 24.2387 6.476 22.644V10.06C6.476 8.396 6.30267 7.18267 5.956 6.42C5.60933 5.62267 5.02 5.10267 4.188 4.86C3.39067 4.58267 2.14267 4.444 0.444 4.444V3.3C2.97467 3.23067 5.05467 3.00533 6.684 2.624C8.348 2.24267 9.83867 1.584 11.156 0.647999V22.644Z" fill="url(#paint0_linear_379_1592)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_379_1592" x1="8.17923" y1="-9" x2="8.17923" y2="27.3363" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className={classes.text3}>Первоначальный брифинг<MediaQuery minWidth={560}><br/></MediaQuery> c Заказчиком, выявление нюансов<MediaQuery minWidth={560}><br/></MediaQuery> и пожеланий</div>
                    </div>
                    <div className={classes.textBlock}>
                        <div className={classes.numberBlock}>
                            <svg width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.688 0.491998C13.1147 0.491998 15.0213 1.064 16.408 2.208C17.7947 3.352 18.488 4.964 18.488 7.044C18.488 8.46533 18.1067 9.852 17.344 11.204C16.616 12.5213 15.7147 13.7347 14.64 14.844C13.6 15.9533 12.2133 17.288 10.48 18.848C9.05867 20.1653 8.00133 21.1707 7.308 21.864C6.61467 22.5227 5.99067 23.2333 5.436 23.996H15.992C16.928 23.996 17.6213 23.9267 18.072 23.788C18.5573 23.6147 18.8867 23.3547 19.06 23.008C19.2333 22.6267 19.372 22.072 19.476 21.344H20.36C20.36 24.5333 20.4293 26.8387 20.568 28.26C19.8053 28.0867 17.2227 28 12.82 28H0.652V27.116C1.24133 26.492 2.24667 25.504 3.668 24.152C5.644 22.3147 7.29067 20.668 8.608 19.212C9.92533 17.7213 11.052 16.04 11.988 14.168C12.9587 12.296 13.444 10.3027 13.444 8.188C13.444 4.444 11.7107 2.572 8.244 2.572C5.40133 2.572 3.07867 4.20133 1.276 7.46L0.444 6.992C1.34533 5.12 2.628 3.57733 4.292 2.364C5.956 1.116 8.088 0.491998 10.688 0.491998Z" fill="#63573A"/>
                                <path d="M10.688 0.491998C13.1147 0.491998 15.0213 1.064 16.408 2.208C17.7947 3.352 18.488 4.964 18.488 7.044C18.488 8.46533 18.1067 9.852 17.344 11.204C16.616 12.5213 15.7147 13.7347 14.64 14.844C13.6 15.9533 12.2133 17.288 10.48 18.848C9.05867 20.1653 8.00133 21.1707 7.308 21.864C6.61467 22.5227 5.99067 23.2333 5.436 23.996H15.992C16.928 23.996 17.6213 23.9267 18.072 23.788C18.5573 23.6147 18.8867 23.3547 19.06 23.008C19.2333 22.6267 19.372 22.072 19.476 21.344H20.36C20.36 24.5333 20.4293 26.8387 20.568 28.26C19.8053 28.0867 17.2227 28 12.82 28H0.652V27.116C1.24133 26.492 2.24667 25.504 3.668 24.152C5.644 22.3147 7.29067 20.668 8.608 19.212C9.92533 17.7213 11.052 16.04 11.988 14.168C12.9587 12.296 13.444 10.3027 13.444 8.188C13.444 4.444 11.7107 2.572 8.244 2.572C5.40133 2.572 3.07867 4.20133 1.276 7.46L0.444 6.992C1.34533 5.12 2.628 3.57733 4.292 2.364C5.956 1.116 8.088 0.491998 10.688 0.491998Z" fill="url(#paint0_linear_379_1593)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_379_1593" x1="10.724" y1="-9" x2="10.724" y2="27.3363" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className={classes.text3}>Составление Коммерческого Предложения и уникального сценария предстоящей съемки</div>
                    </div>
                    <div className={classes.textBlock}>
                        <div className={classes.numberBlock}>
                            <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.804 0.491998C12.988 0.491998 14.7733 1.02933 16.16 2.104C17.5813 3.17867 18.292 4.61733 18.292 6.42C18.292 8.32667 17.5293 10.0427 16.004 11.568C14.5133 13.0933 12.6933 14.2373 10.544 15C13.2133 15.0693 15.484 15.78 17.356 17.132C19.228 18.484 20.164 20.408 20.164 22.904C20.164 25.4 19.1933 27.636 17.252 29.612C15.3453 31.588 12.9013 33.1133 9.92 34.188C6.97333 35.2973 3.97467 35.852 0.924 35.852V34.916C3.38533 34.8467 5.708 34.3267 7.892 33.356C10.076 32.42 11.844 31.0507 13.196 29.248C14.548 27.48 15.224 25.3653 15.224 22.904C15.224 20.8587 14.7213 19.1773 13.716 17.86C12.7107 16.508 11.376 15.7627 9.712 15.624C9.57333 15.6933 9.20933 15.8493 8.62 16.092C8.06533 16.3 7.61467 16.404 7.268 16.404C6.71333 16.404 6.436 16.1787 6.436 15.728C6.436 15.416 6.55733 15.1733 6.8 15C7.07733 14.8267 7.40667 14.74 7.788 14.74C7.996 14.74 8.27333 14.7747 8.62 14.844C9.00133 14.8787 9.33067 14.9133 9.608 14.948C10.96 13.908 11.9653 12.7467 12.624 11.464C13.2827 10.1813 13.612 8.84667 13.612 7.46C13.612 5.86533 13.2133 4.68667 12.416 3.924C11.6187 3.16133 10.5093 2.78 9.088 2.78C7.632 2.78 6.31467 3.17867 5.136 3.976C3.95733 4.73867 2.96933 5.88267 2.172 7.408L1.288 6.992C3.29867 2.65866 6.47067 0.491998 10.804 0.491998Z" fill="#63573A"/>
                                <path d="M10.804 0.491998C12.988 0.491998 14.7733 1.02933 16.16 2.104C17.5813 3.17867 18.292 4.61733 18.292 6.42C18.292 8.32667 17.5293 10.0427 16.004 11.568C14.5133 13.0933 12.6933 14.2373 10.544 15C13.2133 15.0693 15.484 15.78 17.356 17.132C19.228 18.484 20.164 20.408 20.164 22.904C20.164 25.4 19.1933 27.636 17.252 29.612C15.3453 31.588 12.9013 33.1133 9.92 34.188C6.97333 35.2973 3.97467 35.852 0.924 35.852V34.916C3.38533 34.8467 5.708 34.3267 7.892 33.356C10.076 32.42 11.844 31.0507 13.196 29.248C14.548 27.48 15.224 25.3653 15.224 22.904C15.224 20.8587 14.7213 19.1773 13.716 17.86C12.7107 16.508 11.376 15.7627 9.712 15.624C9.57333 15.6933 9.20933 15.8493 8.62 16.092C8.06533 16.3 7.61467 16.404 7.268 16.404C6.71333 16.404 6.436 16.1787 6.436 15.728C6.436 15.416 6.55733 15.1733 6.8 15C7.07733 14.8267 7.40667 14.74 7.788 14.74C7.996 14.74 8.27333 14.7747 8.62 14.844C9.00133 14.8787 9.33067 14.9133 9.608 14.948C10.96 13.908 11.9653 12.7467 12.624 11.464C13.2827 10.1813 13.612 8.84667 13.612 7.46C13.612 5.86533 13.2133 4.68667 12.416 3.924C11.6187 3.16133 10.5093 2.78 9.088 2.78C7.632 2.78 6.31467 3.17867 5.136 3.976C3.95733 4.73867 2.96933 5.88267 2.172 7.408L1.288 6.992C3.29867 2.65866 6.47067 0.491998 10.804 0.491998Z" fill="url(#paint0_linear_379_1595)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_379_1595" x1="11.2151" y1="-9" x2="11.2151" y2="27.3363" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                        </div>
                        <div className={classes.text3}>Внесение корректив и финальное согласование Коммерческого Предложения и сценария</div>
                    </div>
                    <div className={classes.textBlock}>
                        <div className={classes.numberBlock}>
                            <svg width="24" height="36" viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.816 25.452C23.3653 25.348 21.632 25.2613 18.616 25.192V35.332H14.456V25.192H0.884V24.1L17.784 0.491998H18.616V21.708H19.24C20.176 21.708 20.8693 21.6387 21.32 21.5C21.8053 21.3267 22.1347 21.0667 22.308 20.72C22.4813 20.3387 22.62 19.784 22.724 19.056H23.608C23.608 21.968 23.6773 24.1 23.816 25.452ZM14.456 21.708V6.836L3.952 21.708H14.456Z" fill="#63573A"/>
                                <path d="M23.816 25.452C23.3653 25.348 21.632 25.2613 18.616 25.192V35.332H14.456V25.192H0.884V24.1L17.784 0.491998H18.616V21.708H19.24C20.176 21.708 20.8693 21.6387 21.32 21.5C21.8053 21.3267 22.1347 21.0667 22.308 20.72C22.4813 20.3387 22.62 19.784 22.724 19.056H23.608C23.608 21.968 23.6773 24.1 23.816 25.452ZM14.456 21.708V6.836L3.952 21.708H14.456Z" fill="url(#paint0_linear_379_1597)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_379_1597" x1="13.233" y1="-9" x2="13.233" y2="27.3363" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className={classes.text3}>Выезд на площадку,<MediaQuery minWidth={560}><br/></MediaQuery> согласование технических нюансов и производство замеров<span style={{marginTop:'-5px'}}>*</span>
                            <div className={classes.text4}>* возможно без участия Заказчика</div>
                        </div>
                    </div>
                    <div className={classes.textBlock}>
                        <div className={classes.numberBlock}>
                            <svg width="18" height="38" viewBox="0 0 18 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.368 0.62C15.368 4.156 15.4373 6.68667 15.576 8.212C14.848 8.03867 12.4387 7.952 8.348 7.952H2.836L2.212 17.988C3.18267 17.5027 4.11867 17.1213 5.02 16.844C5.92133 16.5667 7.01333 16.428 8.296 16.428C11.312 16.428 13.6 17.1733 15.16 18.664C16.72 20.12 17.5 22.044 17.5 24.436C17.5 27.0013 16.6507 29.2893 14.952 31.3C13.288 33.3453 11.1213 34.9227 8.452 36.032C5.81733 37.1413 3.11333 37.696 0.34 37.696V36.76C2.45467 36.7253 4.43067 36.2573 6.268 35.356C8.14 34.4547 9.648 33.12 10.792 31.352C11.9707 29.584 12.56 27.452 12.56 24.956C12.56 22.772 11.988 21.0733 10.844 19.86C9.7 18.612 8.088 17.988 6.008 17.988C5.176 17.988 4.46533 18.0573 3.876 18.196C3.28667 18.3347 2.576 18.56 1.744 18.872L1.224 18.56L2.212 3.064C2.90533 3.20267 3.84133 3.272 5.02 3.272H10.896C11.832 3.272 12.5253 3.20267 12.976 3.064C13.4613 2.89067 13.7907 2.63067 13.964 2.284C14.1373 1.90266 14.276 1.348 14.38 0.62H15.368Z" fill="#63573A"/>
                                <path d="M15.368 0.62C15.368 4.156 15.4373 6.68667 15.576 8.212C14.848 8.03867 12.4387 7.952 8.348 7.952H2.836L2.212 17.988C3.18267 17.5027 4.11867 17.1213 5.02 16.844C5.92133 16.5667 7.01333 16.428 8.296 16.428C11.312 16.428 13.6 17.1733 15.16 18.664C16.72 20.12 17.5 22.044 17.5 24.436C17.5 27.0013 16.6507 29.2893 14.952 31.3C13.288 33.3453 11.1213 34.9227 8.452 36.032C5.81733 37.1413 3.11333 37.696 0.34 37.696V36.76C2.45467 36.7253 4.43067 36.2573 6.268 35.356C8.14 34.4547 9.648 33.12 10.792 31.352C11.9707 29.584 12.56 27.452 12.56 24.956C12.56 22.772 11.988 21.0733 10.844 19.86C9.7 18.612 8.088 17.988 6.008 17.988C5.176 17.988 4.46533 18.0573 3.876 18.196C3.28667 18.3347 2.576 18.56 1.744 18.872L1.224 18.56L2.212 3.064C2.90533 3.20267 3.84133 3.272 5.02 3.272H10.896C11.832 3.272 12.5253 3.20267 12.976 3.064C13.4613 2.89067 13.7907 2.63067 13.964 2.284C14.1373 1.90266 14.276 1.348 14.38 0.62H15.368Z" fill="url(#paint0_linear_379_1600)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_379_1600" x1="9.19716" y1="-7" x2="9.19716" y2="29.3363" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className={classes.text3}>В день мероприятия:<br/> монтаж на площадке, далее<MediaQuery minWidth={560}><br/></MediaQuery> съемочный процесс. Демонтаж</div>
                    </div>
                    <div className={classes.textBlock}>
                        <div className={classes.numberBlock}>
                            <svg width="23" height="39" viewBox="0 0 23 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.548 15.224C15.212 15.224 16.7547 15.6747 18.176 16.576C19.5973 17.4773 20.7413 18.7947 21.608 20.528C22.4747 22.2267 22.908 24.2373 22.908 26.56C22.908 29.0907 22.388 31.2747 21.348 33.112C20.3427 34.9493 18.9733 36.3533 17.24 37.324C15.5067 38.26 13.5827 38.728 11.468 38.728C8.14 38.728 5.54 37.428 3.668 34.828C1.796 32.228 0.86 28.6053 0.86 23.96C0.86 19.1067 1.77867 14.9987 3.616 11.636C5.488 8.23867 7.75867 5.656 10.428 3.888C13.0973 2.08533 15.784 0.923999 18.488 0.403997L18.696 1.444C16.5813 2.13733 14.6747 3.07333 12.976 4.252C11.312 5.43067 9.83867 7.25067 8.556 9.712C7.27333 12.1733 6.45867 15.4147 6.112 19.436C6.90933 17.98 8.00133 16.9227 9.388 16.264C10.7747 15.5707 12.1613 15.224 13.548 15.224ZM11.988 37.688C13.6867 37.688 15.0907 36.804 16.2 35.036C17.3093 33.268 17.864 30.564 17.864 26.924C17.864 23.388 17.344 20.8053 16.304 19.176C15.264 17.5467 13.912 16.732 12.248 16.732C11.0347 16.732 9.82133 17.096 8.608 17.824C7.42933 18.5173 6.54533 19.644 5.956 21.204C5.92133 21.7587 5.904 22.6427 5.904 23.856C5.904 28.848 6.49333 32.4013 7.672 34.516C8.85067 36.6307 10.2893 37.688 11.988 37.688Z" fill="#63573A"/>
                                <path d="M13.548 15.224C15.212 15.224 16.7547 15.6747 18.176 16.576C19.5973 17.4773 20.7413 18.7947 21.608 20.528C22.4747 22.2267 22.908 24.2373 22.908 26.56C22.908 29.0907 22.388 31.2747 21.348 33.112C20.3427 34.9493 18.9733 36.3533 17.24 37.324C15.5067 38.26 13.5827 38.728 11.468 38.728C8.14 38.728 5.54 37.428 3.668 34.828C1.796 32.228 0.86 28.6053 0.86 23.96C0.86 19.1067 1.77867 14.9987 3.616 11.636C5.488 8.23867 7.75867 5.656 10.428 3.888C13.0973 2.08533 15.784 0.923999 18.488 0.403997L18.696 1.444C16.5813 2.13733 14.6747 3.07333 12.976 4.252C11.312 5.43067 9.83867 7.25067 8.556 9.712C7.27333 12.1733 6.45867 15.4147 6.112 19.436C6.90933 17.98 8.00133 16.9227 9.388 16.264C10.7747 15.5707 12.1613 15.224 13.548 15.224ZM11.988 37.688C13.6867 37.688 15.0907 36.804 16.2 35.036C17.3093 33.268 17.864 30.564 17.864 26.924C17.864 23.388 17.344 20.8053 16.304 19.176C15.264 17.5467 13.912 16.732 12.248 16.732C11.0347 16.732 9.82133 17.096 8.608 17.824C7.42933 18.5173 6.54533 19.644 5.956 21.204C5.92133 21.7587 5.904 22.6427 5.904 23.856C5.904 28.848 6.49333 32.4013 7.672 34.516C8.85067 36.6307 10.2893 37.688 11.988 37.688Z" fill="url(#paint0_linear_379_1601)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_379_1601" x1="11.742" y1="0.999997" x2="11.742" y2="37.3363" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className={classes.text3}>Частичное предоставление отснятых материалов прямо<MediaQuery minWidth={560}><br/></MediaQuery> во время мероприятия</div>
                    </div>
                </div>
                {false&&<div>
                    <div className={classes.texts}>
                        {text1.map(item=>
                            <div key={item.id} className={classes.textBlock}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" style={{minWidth:'6px',minHeight:'7px'}}  viewBox="0 0 6 7" fill="none">
                                    <circle cx="3" cy="3.5" r="3" fill="white"/>
                                </svg>
                                <div className={classes.text3}>
                                    <MediaQuery minWidth={420}>
                                        <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={419}>
                                        <div dangerouslySetInnerHTML={{__html: item.textAll}}></div>
                                    </MediaQuery>
                                    {item.text2 && <div className={classes.text4} dangerouslySetInnerHTML={{__html: item.text2}}></div>}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.texts2}>
                        {text2.map(item=>
                            <div key={item.id} className={classes.textBlock}>
                                {item.text && <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" style={{minWidth:'6px',minHeight:'7px'}}  viewBox="0 0 6 7" fill="none">
                                    <circle cx="3" cy="3.5" r="3" fill="white"/>
                                </svg>}
                                <div className={classes.text3}>
                                    <MediaQuery minWidth={420}>
                                        <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={419}>
                                        <div dangerouslySetInnerHTML={{__html: item.textAll}}></div>
                                    </MediaQuery>
                                    {item.text2 && <div className={classes.text4} dangerouslySetInnerHTML={{__html: item.text2}}></div>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Block6;