import React, {useEffect, useState} from 'react';
import classes from "./block2.module.css";
import {useInView} from "react-intersection-observer";
import MediaQuery from "react-responsive";

const Block2 = () => {
    const {ref, inView} = useInView({
        threshold:.8,
        triggerOnce:true
    })
    const [view, setView] = useState(false)

    useEffect(()=>{
        setView(inView)
    },[inView])

    return (
        <div className={classes._} ref={ref}>
            <div className={classes.block}>
                <div className={classes.text1+' '+(view && classes.view1)}>Каждый из нас<br/>хоть раз в жизни <MediaQuery maxWidth={599}><br/></MediaQuery><span className={classes.text2_1}>мечтал</span>...
                </div>
                <div className={classes.text2+' '+(view && classes.view2)}>почувствовать себя звездой, купающейся в лучах софитов, <MediaQuery minWidth={450}><br/></MediaQuery>и команда TOPDAY дает своим клиентам такую возможность!</div>
                <div className={classes.text3+' '+(view && classes.view3)}>Вспышки фотоаппаратов, непрерывно работающая наплывом камера, <MediaQuery minWidth={450}><br/></MediaQuery>вентиляторы на полу и харизматичный режиссер, помогающий гостю <MediaQuery minWidth={450}><br/></MediaQuery>с позиционированием и вовлекающий в процесс – эти по-настоящему Голливудские моменты запомнятся на всю жизнь!</div>
            </div>
        </div>
    );
};

export default Block2;